import React, { useContext } from "react"
import { Link } from "gatsby"
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links"
import CircleBLur from "@common/CircleBlur/CircleBlur"
import { setToLocalStorage } from "../../helpers"
import { ConsentContext } from "../../contexts/ConsentContext"
import {
  COOKIE_POLICY_HEADING,
  COOKIE_OVERVIEW_HEADING,
  COOKIE_LIST_HEADING,
  COOKIE_LIST_DESCRIPTION,
  COOKIES_LIST,
} from "./constants"
import { 
  StyledCookiePolicyWrapper,
  StyledCookiePolicyTitle,
  StyledCookiePolicyDescription,
  StyledLink,
  StyledCookiePolicySubtitle,
  StyledCookieOverviewContainer,
  StyledCookiePolicyText,
  StyledCookiePolicyInformation,
  StyledChangeConsentOption,
  StyledCookieListWrapper,
  StyledCookieListContainer
} from "./styled";

const CookiePolicy = () => {
  const { currentConsentState, consentCreationDate, setOpenForEdit } = useContext(ConsentContext)

  const handleConsentEdit = () => {
    setOpenForEdit(true)
    setToLocalStorage("consent_edit", true)
  }
  return (
    <StyledCookiePolicyWrapper>
      <CircleBLur
        blur="blur(25rem)"
        width="39.6875rem"
        left="-19.0625rem"
        height="39.6875rem"
      />
      <StyledCookiePolicyTitle>{COOKIE_POLICY_HEADING}</StyledCookiePolicyTitle>
      <StyledCookiePolicyDescription>
        This website utilizes cookies to enhance your user experience by
        personalizing content and advertisements, integrating social media
        features, and analyzing website traffic. We may disclose information
        regarding your website usage to our social media, advertising, and
        analytics partners. These partners may combine this information with
        data you have provided them directly or information collected through
        your use of their services. Cookies are small data files commonly used
        by websites to streamline user experiences. Our website utilizes various
        cookies, including those placed by third-party services incorporated
        within our pages. You retain the right to modify or revoke your cookie
        consent at any time through our website's Cookie Declaration. For
        further information regarding our company, methods of contacting us, and
        our personal data processing practices, please refer to our{" "}
        <StyledLink
          to={GLOBAL_APPLICATION_INTERNAL_LINKS.PRIVACY_POLICY}
        >
          Privacy Policy
        </StyledLink>
        . When contacting us in reference to your consent, kindly reference your
        consent ID and date.
      </StyledCookiePolicyDescription>
      <StyledCookiePolicySubtitle>{COOKIE_OVERVIEW_HEADING}</StyledCookiePolicySubtitle>
      <StyledCookieOverviewContainer>
        <StyledCookiePolicyText>
          Your consent applies to the following domains:{" "}
          <StyledCookiePolicyInformation>impressit.io</StyledCookiePolicyInformation>
        </StyledCookiePolicyText>
        <StyledCookiePolicyText>
          Your current state:{" "}
          <StyledCookiePolicyInformation>{currentConsentState}</StyledCookiePolicyInformation>
        </StyledCookiePolicyText>
        <StyledCookiePolicyText>
          Consent date:{" "}
          <StyledCookiePolicyInformation>{consentCreationDate}</StyledCookiePolicyInformation>
        </StyledCookiePolicyText>
        <StyledChangeConsentOption onClick={() => handleConsentEdit()}>
          Change your consent
        </StyledChangeConsentOption>
      </StyledCookieOverviewContainer>

      <StyledCookiePolicySubtitle>{COOKIE_LIST_HEADING}</StyledCookiePolicySubtitle>
      <StyledCookieListWrapper>
        <StyledCookiePolicyText>{COOKIE_LIST_DESCRIPTION}</StyledCookiePolicyText>
        <StyledCookieListContainer>
          {COOKIES_LIST.map(({ title, description }) => (
            <div>
              <h3>{title}</h3>
              <StyledCookiePolicyText>{description}</StyledCookiePolicyText>
            </div>
          ))}
          <div>
            <h3>Contact Us</h3>
            <StyledCookiePolicyText>
              If you have any questions about our use of cookies or your
              personal information, please review our Privacy Policy or contact
              us directly: Email:{" "}
              <Link
                to="mailto:team@impressit.io"
                className="privacy-policy__highlited"
              >
                team@impressit.io
              </Link>
            </StyledCookiePolicyText>
          </div>
        </StyledCookieListContainer>
      </StyledCookieListWrapper>
      <CircleBLur
        blur="blur(25rem)"
        width="39.6875rem"
        left="-19.0625rem"
        height="39.6875rem"
      />
    </StyledCookiePolicyWrapper>
  )
}

export default CookiePolicy
