import styled from "styled-components"
import { Link } from "gatsby"

export const StyledCookiePolicyWrapper = styled.div`
  padding-bottom: 11.25rem;
  max-width: 56.25rem;
  margin: 0 auto;

  @media only screen and (min-width: 768px) and (max-width: 1350px) {
    padding-bottom: 5.75rem;
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 4.5625rem;
  }
`

export const StyledCookiePolicyTitle = styled.h1`
  color: white;
  font-size: 4rem;
  line-height: 5.4375rem;
  font-weight: bold;
  margin: 0;

  @media only screen and (min-width: 768px) and (max-width: 1350px) {
    width: 100%;
    font-size: 2.5rem;
    line-height: 3rem;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.9375rem;
  }
`
export const StyledCookiePolicyDescription = styled.p`
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.625rem;
  margin: 6.75rem 0;
  color: var(--main-font-color);

  @media only screen and (min-width: 768px) and (max-width: 1350px) {
    margin: 2.6875rem 0 5.625rem;
  }

  @media only screen and (max-width: 767px) {
    margin: 1.5rem 0 2.5rem;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #c4b180;
`

export const StyledCookiePolicySubtitle = styled.h2`
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.4375rem;

  @media only screen and (min-width: 768px) and (max-width: 1350px) {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin: 0;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.9375rem;
  }
`
export const StyledCookieOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 1.3rem;
`

export const StyledCookiePolicyText = styled.p`
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.625rem;
  margin: 0;
  color: var(--main-font-color);
`

export const StyledCookiePolicyInformation = styled.span`
    color: #ffffff;
`;

export const StyledChangeConsentOption = styled.p`
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.625rem;
  margin: 0;
  color: var(--main-gold);
  cursor: pointer;
  margin-bottom: 1rem;
`

export const StyledCookieListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
`;

export const StyledCookieListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
`;
