export const COOKIE_POLICY_HEADING = "Cookie Policy for Impressit"
export const COOKIE_OVERVIEW_HEADING = "Cookie Overview"
export const COOKIE_LIST_HEADING = "Cookie List"
export const COOKIE_LIST_DESCRIPTION = `This website employs three distinct cookie categories to optimize your
experience: strictly necessary cookies, analytics cookies, and
marketing cookies. A detailed explanation of each category and a
corresponding list of cookies within those categories is provided
below.`

export const COOKIES_LIST = [
  {
    title: "Necessary Cookies",
    description: `These cookies are essential for the website's core functionality
    and cannot be disabled within our systems. Their activation is
    typically triggered by user actions that constitute a service
    request, such as configuring privacy preferences, logging in, or
    completing forms. These cookies do not retain any personally
    identifiable information.`,
  },
  {
    title: "Analytics Cookies",
    description: `These cookies permit us to quantify website visitation and traffic
    sources. This data is instrumental in gauging and enhancing the website's
    performance. The information gathered by these cookies is anonymized
    through aggregation. Should you disable these cookies, we will be unable
    to ascertain your visitations or monitor the website's effectiveness.`,
  },
  {
    title: "Marketing Cookies",
    description: `This website utilizes marketing cookies to implement various forms of
    targeted digital marketing initiatives. These initiatives are employed
    with the dual purpose of enhancing your user experience and supporting the
    ongoing maintenance, operation, and improvement of our website. Marketing
    cookies function by accumulating user data and behavioral information,
    which is then leveraged by advertising services to tailor advertisements
    to specific audience segments based on pre-defined characteristics.`,
  },
  {
    title: "Preferences Cookies",
    description: `This relates to storing data that enhances the personalization of content
    beyond advertising. For example, video recommendations on a streaming
    platform would rely on this type of storage to tailor content suggestions
    to the individual user's tastes and viewing history.`,
  },
  {
    title: "Partners Cookies",
    description: `This controls whether services can use the stored data to personalize ads.
    This includes remarketing or showing ads to users based on their previous
    interactions with the website or app. Personalized ads are tailored to the
    user's interests, making them more relevant.`,
  },
  {
    title: "Changes to the Policy",
    description: `This policy might be updated occasionally. If major changes are made,
    we'll try to let you know. This could be through a clear notice on our
    website for a while, or even directly via email (depending on what's
    reasonable)`,
  },
]
